import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import { addNFTImageStart } from "../store/actions/NFTActions";

const AddNFTImage = (props) => {
  const [properties, setProperties] = useState([
    "type",
    "material",
    "key_color",
    "key_bit_type",
    "rn_bit_pattern",
    "closed_ward_shapes",
    "side_cut_wards",
    "tip",
    "collar_type",
    "stem",
    "bow",
    "eye_color",
    "extra",
    "key_fob",
    "chain",
    "track",
    // "background",
    "protocol",
  ]);

  const [inputData, setInputData] = useState({});

  const [image, setImage] = useState({
    nft_image: {
      file: "",
      preview_image: "",
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(addNFTImageStart(inputData));
  };

  const handleProfileImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setImage({
        ...image,
        nft_image: {
          file: file,
          preview_image: reader.result,
        },
      });
    };
    setInputData({
      ...inputData,
      picture: file,
    });
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleProfileImageRemove = () => {
    setImage({
      ...image,
      nft_image: {
        file: "",
        preview_image: "",
      },
    });
  };

  const handleInputChange = (event) => {
    setInputData({
      ...inputData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <main class="h-full pb-16 overflow-y-auto content-wrapper">
        <div class="container px-6 mx-auto grid content-section">
          <div class="apps-card">
            <div class="px-4 py-3 mb-8 app-card w-100 mt-4">
              <h2 class="my-6 text-2xl font-semibold ">Add NFT Image</h2>
              <Form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col lg={6}>
                    <div className="custom-input">
                      <label class="block">
                        <span>Name</span>
                        <input
                          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                          placeholder="Enter Name"
                          name="name"
                          onChange={(event) => handleInputChange(event)}
                        />
                      </label>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="custom-input">
                      <label class="block">
                        <span>Description</span>
                        <input
                          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                          placeholder="Enter Description"
                          name="description"
                          onChange={(event) => handleInputChange(event)}
                        />
                      </label>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="custom-input">
                      <label class="block">
                        <span>Preview Image</span>
                        <input
                          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                          placeholder="Enter Image Url"
                          name="image"
                          onChange={(event) => handleInputChange(event)}
                        />
                      </label>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="custom-input">
                      <label class="block">
                        <span>Animation Url</span>
                        <input
                          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                          placeholder="Enter Animation Url"
                          name="animation_url"
                          onChange={(event) => handleInputChange(event)}
                        />
                      </label>
                    </div>
                  </Col>
                  {properties.map((data, index) => (
                    <>
                      <Col lg={6}>
                        <div className="custom-input">
                          <label class="block">
                            <span class="text-capitalize">
                              {data.replace(/_/g, " ")}
                            </span>
                            <input
                              class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                              placeholder={`Enter ${data.replace(/_/g, " ")}`}
                              name={data}
                              onChange={(event) => handleInputChange(event)}
                            />
                          </label>
                        </div>
                      </Col>
                    </>
                  ))}
                  {/* <Col lg={12}>
                    <Row>
                      <Col lg={5}>
                        <div className="block text-sm mb-2">
                          <label class="block">
                            <span>NFT Image</span>
                          </label>
                          <div className="custom-file-btn-wrapper ">
                            <input
                              id="fileSelect"
                              type="file"
                              accept="image/*"
                              name="picture"
                              onChange={(event) => {
                                handleProfileImageChange(event);
                              }}
                            />
                            <button className=" px-4 py-2 text-sm font-medium leading-5 content-button status-button">
                              Choose NFT Image
                            </button>
                          </div>
                        </div>
                      </Col>

                      <Col lg={7}>
                        {image.nft_image.preview_image != "" && (
                          <div className="preview-image-wrapper">
                            <img
                              src={image.nft_image.preview_image}
                              alt=""
                              className="settings-previewimage "
                            />
                            <div className="file-remove-btn">
                              <button
                                className=" flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-full active:bg-red-600 hover:bg-red-600 focus:outline-none focus:shadow-outline-purple"
                                aria-label="Delete"
                                onClick={handleProfileImageRemove}
                              >
                                <svg
                                  fill="#fff"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  width="24"
                                >
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col> */}
                </Row>
                <div className="w-100 text-center">
                  <button
                    type="submit"
                    className="content-button status-button"
                    disabled={props.addNFTImage.buttonDisable}
                  >
                    {props.addNFTImage.loadingButtonContent != null
                      ? props.addNFTImage.loadingButtonContent
                      : "Add NFT image"}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

const mapStateToPros = (state) => ({
  addNFTImage: state.nft.addNFTImage,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddNFTImage);
