import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { fetchSingleNFTStart } from "../store/actions/NFTActions";
import { Row, Col, Form } from "react-bootstrap";
import {
  editNFTDetails,
  updateNFTDetailsStart,
} from "../store/actions/NFTActions";

const EditNFT = (props) => {
  const { id } = useParams();

  const [image, setImage] = useState({
    nft_image: {
      file: "",
      preview_image: "",
    },
  });

  useEffect(() => {
    props.dispatch(fetchSingleNFTStart({ nft_image_id: id }));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(updateNFTDetailsStart({ nft_image_id: id }));
  };

  const handleProfileImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setImage({
        ...image,
        nft_image: {
          file: file,
          preview_image: reader.result,
        },
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleProfileImageRemove = () => {
    setImage({
      ...image,
      nft_image: {
        file: "",
        preview_image: "",
      },
    });
  };

  return (
    <>
      <main class="h-full pb-16 overflow-y-auto content-wrapper">
        <div class="container px-6 mx-auto grid content-section">
          <div className="apps-card">
            <div class="px-4 py-3 mb-8 app-card mt-4 w-100">
              <h2 class="my-6 text-2xl font-semibold">Edit NFT</h2>
              <Form onSubmit={(event) => handleSubmit(event)}>
                {props.singleNFT.loading
                  ? "Loading.."
                  : props.singleNFT.data.nft_image && (
                      <>
                        <Row>
                          <Col lg={6}>
                            <div className="custom-input">
                              <label class="block">
                                <span>Name</span>
                                <input
                                  class="block form-input"
                                  placeholder="Enter Name"
                                  name="name"
                                  defaultValue={
                                    props.singleNFT.data.nft_image.name
                                  }
                                  onChange={(event) => {
                                    props.dispatch(
                                      editNFTDetails(
                                        event.currentTarget.name,
                                        event.currentTarget.value
                                      )
                                    );
                                  }}
                                />
                              </label>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="custom-input">
                              <label class="block">
                                <span>Description</span>
                                <input
                                  class="block form-input"
                                  placeholder="Enter Description"
                                  name="description"
                                  defaultValue={
                                    props.singleNFT.data.nft_image
                                      .description != null
                                      ? props.singleNFT.data.nft_image
                                          .description
                                      : ""
                                  }
                                  onChange={(event) => {
                                    props.dispatch(
                                      editNFTDetails(
                                        event.currentTarget.name,
                                        event.currentTarget.value
                                      )
                                    );
                                  }}
                                />
                              </label>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="custom-input">
                              <label class="block">
                                <span>Preview Image</span>
                                <input
                                  class="block form-input"
                                  placeholder="Enter Name"
                                  name="image"
                                  defaultValue={
                                    props.singleNFT.data.nft_image.image
                                  }
                                  onChange={(event) => {
                                    props.dispatch(
                                      editNFTDetails(
                                        event.currentTarget.name,
                                        event.currentTarget.value
                                      )
                                    );
                                  }}
                                />
                              </label>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="custom-input">
                              <label class="block">
                                <span>Animation Url</span>
                                <input
                                  class="block form-input"
                                  placeholder="Enter Name"
                                  name="animation_url"
                                  defaultValue={
                                    props.singleNFT.data.nft_image.animation_url
                                  }
                                  onChange={(event) => {
                                    props.dispatch(
                                      editNFTDetails(
                                        event.currentTarget.name,
                                        event.currentTarget.value
                                      )
                                    );
                                  }}
                                />
                              </label>
                            </div>
                          </Col>
                          {Object.entries(
                            props.singleNFT.data.nft_image.nft_properties
                          )
                            .filter(
                              (data) =>
                                data[0] != "nft_image_id" &&
                                data[0] != "key" &&
                                data[0] != "value" &&
                                data[0] != "created_at" &&
                                data[0] != "updated_at" &&
                                data[0] != "nft_property_id" &&
                                data[0] != "nft_property_unique_id" &&
                                data[0] != "status"
                            )
                            .map((data, index) => (
                              <>
                                <Col lg={6} key={index}>
                                  <div className="custom-input">
                                    <label class="block">
                                      <span class="text-capitalize">
                                        {data[0].replace(/_/g, " ")}
                                      </span>
                                      <input
                                        class="block form-input"
                                        placeholder={`Enter ${data[0].replace(
                                          /_/g,
                                          " "
                                        )}`}
                                        name={data[0]}
                                        defaultValue={data[1]}
                                        onChange={(event) => {
                                          props.dispatch(
                                            editNFTDetails(
                                              event.currentTarget.name,
                                              event.currentTarget.value
                                            )
                                          );
                                        }}
                                      />
                                    </label>
                                  </div>
                                </Col>
                              </>
                            ))}
                          {/* <Col lg={12}>
                            <Row>
                              <Col lg={5}>
                                <div className="block text-sm mb-2">
                                  <label class="block text-sm mb-1">
                                    <span>NFT Image</span>
                                  </label>
                                  <div className="custom-file-btn-wrapper ">
                                    <input
                                      id="fileSelect"
                                      type="file"
                                      accept="image/*"
                                      name="picture"
                                      onChange={(event) => {
                                        handleProfileImageChange(event);
                                        props.dispatch(
                                          editNFTDetails(
                                            event.currentTarget.name,
                                            event.currentTarget.files[0]
                                          )
                                        );
                                      }}
                                    />
                                    <button className=" px-4 py-2  font-medium leading-5 content-button status-button ">
                                      Choose NFT Image
                                    </button>
                                  </div>
                                </div>
                              </Col>

                              <Col lg={7}>
                                {image.nft_image.preview_image != "" ? (
                                  <div className="preview-image-wrapper">
                                    <img
                                      src={image.nft_image.preview_image}
                                      alt=""
                                      className="settings-previewimage "
                                    />
                                    <div className="file-remove-btn">
                                      <button
                                        className=" flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-full active:bg-red-600 hover:bg-red-600 focus:outline-none focus:shadow-outline-purple"
                                        aria-label="Delete"
                                        onClick={handleProfileImageRemove}
                                      >
                                        <svg
                                          fill="#fff"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          width="24"
                                        >
                                          <path d="M0 0h24v24H0z" fill="none" />
                                          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  props.singleNFT.data.nft_image.picture !=
                                    "" && (
                                    <div>
                                      <img
                                        src={
                                          props.singleNFT.data.nft_image.picture
                                        }
                                        alt=""
                                        className="settings-previewimage "
                                      />
                                    </div>
                                  )
                                )}
                              </Col>
                            </Row>
                          </Col> */}
                        </Row>
                        <div className="w-100 text-center app-card-buttons sub-btn">
                          <button
                            className="content-button status-button"
                            onClick={handleSubmit}
                            disabled={props.editNFTInputdata.buttonDisable}
                          >
                            {props.editNFTInputdata.loadingButtonContent !==
                            null
                              ? props.editNFTInputdata.loadingButtonContent
                              : "Submit"}
                          </button>
                        </div>
                      </>
                    )}
              </Form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

const mapStateToPros = (state) => ({
  singleNFT: state.nft.singleNFT,
  editNFTInputdata: state.nft.editNFTInputdata,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditNFT);
