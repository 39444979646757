import React, { useEffect, useState } from "react";
import NftLoot from "../../abis/TracksToMetaverse.json";
import Web3 from "web3";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";

const ConfigIndex = (props) => {
  useEffect(() => { }, []);

  const [nftLoot, setNftLoot] = useState(null);
  const [connectMetaMask, setConnectMetaMask] = useState(false);
  const [account, setAccount] = useState("");
  const [ethBalance, setEthBalance] = useState("");
  const [currentLimit, setCurrentLimit] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [limit, setLimit] = useState("");
  const [buttonContent, setButtonContent] = useState("");
  const [minterAddress, setMinterAddress] = useState("");
  const [burnerAddress, setBurnerAddress] = useState("");
  const [revokeMinterAddress, setRevokeMinterAddress] = useState("");
  const [revokeBurnerAddress, setRevokeBurnerAddress] = useState("");

  const connectingMetaMask = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      console.log("Etherum enabled");
      setConnectMetaMask(true);
      getWalletAddress();
      return true;
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      setConnectMetaMask(true);
      getWalletAddress();
      return true;
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
      return false;
    }
  };

  const getWalletAddress = async () => {
    const web3 = window.web3;
    try {
      // Network ID
      const networkId = await web3.eth.net.getId();
      const networkData = NftLoot.networks[networkId];
      // if (networkData) {
      // const nftLoot = new web3.eth.Contract(NftLoot.abi, configuration.get("configData.contract_address"));
      const nftLoot = new web3.eth.Contract(
        NftLoot.abi,
        "0x413481540052112FcEf3B875Cb0B84f0A8674B0A"
      );
      setNftLoot(nftLoot);
      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);
      const totalSupply = await nftLoot.methods.totalSupply().call();
      console.log("Total", totalSupply);
      const owner = await nftLoot.methods.owner().call();
      console.log("Owner", owner);

      const ethBalance = await web3.eth.getBalance(accounts[0]);
      setEthBalance(ethBalance);
      setConnectMetaMask(true);
      // } else {
      //   window.alert("Contract not deployed to detected network.");
      // }
    } catch (error) {
      console.log("Errror", error);
      window.alert("Error occuried, Refresh the page");
    }
  };

  const grandMintAccess = async (event) => {
    event.preventDefault();
    console.log("minter", minterAddress);
    console.log("account", account);
    nftLoot.methods
      .grandMinterRole(minterAddress)
      .send({ from: account })
      .on("transactionHash", (hash) => {
        console.log("Trx", hash);
      });
  };

  const grandBurnAccess = async (event) => {
    event.preventDefault();
    nftLoot.methods
      .grandBurnerRole(burnerAddress)
      .send({ from: account })
      .on("transactionHash", (hash) => {
        console.log("Trx", hash);
      });
  };

  const revokeMintAccess = async (event) => {
    event.preventDefault();
    nftLoot.methods
      .revokeMinterAccess(revokeMinterAddress)
      .send({ from: account })
      .on("transactionHash", (hash) => {
        console.log("Trx", hash);
      });
  };

  const revokeBurnAccess = async (event) => {
    event.preventDefault();
    nftLoot.methods
      .revokeBurnerAccess(revokeBurnerAddress)
      .send({ from: account })
      .on("transactionHash", (hash) => {
        console.log("Trx", hash);
      });
  };

  return (
    <>
      <main className="h-full pb-16 overflow-y-auto content-wrapper">
        <div className="container  mt-0 mx-auto grid content-section">
          <div className="apps-card">
            <div className="px-4 py-3 mb-8 app-card mt-4 right-card-width">
              <h2 className="my-6 text-2xl font-semibold">
                BlockChain Configuration -{" "}
                <Link to="#" onClick={connectingMetaMask}>
                  {connectMetaMask ? "Wallet Connected" : "Connect Wallet"}
                </Link>
              </h2>
              {connectMetaMask ? (
                <>
                  <h4>Current Limit: {currentLimit}</h4>
                  <br />
                  <h4>Total Supply: {totalSupply}</h4>
                  <br />
                </>
              ) : (
                ""
              )}
              <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label className="block">
                    <span>Give Minter Access</span>
                    <input
                      className="block form-input"
                      placeholder="Enter Minter Address"
                      type="text"
                      name="minter"
                      value={minterAddress}
                      onChange={(event) => setMinterAddress(event.target.value)}
                    />
                  </label>
                </div>
                <div className="app-card-buttons btn-align">
                  <button
                    className="content-button status-button"
                    onClick={grandMintAccess}
                    disabled={buttonContent != "" ? true : false}
                  >
                    {buttonContent != "" ? buttonContent : "Submit"}
                  </button>
                </div>
              </div>

              <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label className="block">
                    <span>Give Burner Access</span>
                    <input
                      className="block form-input"
                      placeholder="Enter Burner Address"
                      type="text"
                      name="burner"
                      value={burnerAddress}
                      onChange={(event) => setBurnerAddress(event.target.value)}
                    />
                  </label>
                </div>
                <div className="app-card-buttons btn-align">
                  <button
                    className="content-button status-button"
                    onClick={grandBurnAccess}
                    disabled={buttonContent != "" ? true : false}
                  >
                    {buttonContent != "" ? buttonContent : "Submit"}
                  </button>
                </div>
              </div>

              <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label className="block">
                    <span>Revoke Burner Access</span>
                    <input
                      className="block form-input"
                      placeholder="Enter Burner Address"
                      type="text"
                      name="revokeBurner"
                      value={revokeBurnerAddress}
                      onChange={(event) =>
                        setRevokeBurnerAddress(event.target.value)
                      }
                    />
                  </label>
                </div>
                <div className="app-card-buttons btn-align">
                  <button
                    className="content-button status-button"
                    onClick={revokeBurnAccess}
                    disabled={buttonContent != "" ? true : false}
                  >
                    {buttonContent != "" ? buttonContent : "Submit"}
                  </button>
                </div>
              </div>

              <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label className="block">
                    <span>Revoke Minter Access</span>
                    <input
                      className="block form-input"
                      placeholder="Enter Minter Address"
                      type="text"
                      name="revokeMinter"
                      value={revokeMinterAddress}
                      onChange={(event) =>
                        setRevokeMinterAddress(event.target.value)
                      }
                    />
                  </label>
                </div>
                <div className="app-card-buttons btn-align">
                  <button
                    className="content-button status-button"
                    onClick={revokeMintAccess}
                    disabled={buttonContent != "" ? true : false}
                  >
                    {buttonContent != "" ? buttonContent : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(ConfigIndex);
