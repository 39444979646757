import React, { useState, useEffect } from "react";
import { Nav, Container, Navbar, Row, Col, Card, Form, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import "./HomePageIndex.css";
import NftLoot from "../../abis/KeyToMetaverseBSC.json";
import Web3 from "web3";
import { apiConstants } from "../Constant/constants";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import InputData from "../Data/InputData.json";
import "./assets/css/default.css";
//Declare IPFS
const auth = 'Basic' + Buffer.from(apiConstants.ipfs_project_id + ':' + apiConstants.ipfs_project_secret).toString('base64');
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
    host: "ipfs.infura.io",
    port: 5001,
    protocol: "https",
    headers: {
        authorization: auth
    }
}); // leaving out the arguments will default to these values

// Declare IPFS json
const ipfsJson = require("nano-ipfs-store").at("https://ipfs.infura.io:5001");

const HomePageIndex = (props) => {

    const history = useHistory();

    const [nftLoot, setNftLoot] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tokenURIs, setTokenURLs] = useState([]);
    const [data, setData] = useState([]);
    const [totalSupply, setTotalSupply] = useState("");
    const [startFetching, setStartFetching] = useState(false);
    const [fetchCount, setFetchCount] = useState(3);
    const [initCount, setInitCount] = useState(0);
    const [noMoreData, setNoMoreData] = useState(false);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [loadMoreContent, setLoadMoreContent] = useState("");
    const [connectMetaMask, setConnectMetaMask] = useState(false);
    const [account, setAccount] = useState("");
    const [ethBalance, setEthBalance] = useState("");
    const [minterWalletAddress, setMinterWalletAdddress] = useState("");
    const [mintButtonContent, setMintButtonContent] = useState("");
    const [inputData, setInputData] = useState({
        title: "",
        description: ""
    });
    const [images, setImages] = useState([]);
    const [img, setImg] = useState("");
    const [nftWords, setNftWords] = useState([]);
    const [responseData, setResponseData] = useState("");

    useEffect(() => {
        loadWeb3();
    }, []);


    const connectingMetaMask = async () => {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
            console.log("Etherum enabled");
            setConnectMetaMask(true);
            getWalletAddress();
            return true;
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider);
            setConnectMetaMask(true);
            getWalletAddress();
            return true;
        } else {
            window.alert(
                "Non-Ethereum browser detected. You should consider trying MetaMask!"
            );
            return false;
        }
    };


    const getWalletAddress = async () => {
        const web3 = window.web3;
        try {
            // Network ID
            const networkId = await web3.eth.net.getId();
            const networkData = NftLoot.networks[networkId];
            if (networkData) {
                const nftLoot = new web3.eth.Contract(NftLoot.abi, configuration.get("configData.contract_address"));
                setNftLoot(nftLoot);
                const accounts = await web3.eth.getAccounts();
                setAccount(accounts[0]);

                const ethBalance = await web3.eth.getBalance(accounts[0]);
                setEthBalance(ethBalance);
                setConnectMetaMask(true);
            } else {
                window.alert("Contract not deployed to detected network.");
            }
        } catch (error) {
            window.alert("Error occuried, Refresh the page");
        }
    };


    const convertDataURIToBinaryFF = (dataURI) => {
        var BASE64_MARKER = ";base64,";
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var raw = window.atob(dataURI.substring(base64Index));
        return Uint8Array.from(
            Array.prototype.map.call(raw, function (x) {
                return x.charCodeAt(0);
            })
        );
    };

    // Generate metadata json file. 
    const generateJson = async (data) => {
        const metadata = JSON.stringify({
            description: data.description,
            external_url: "https://keystometaverse.com",
            image: "https://ipfs.infura.io/ipfs/" + data.imageHash,
            name: data.name,
            text: data.allWords,
            attributes: [
                data.type != "" ?
                    {
                        "trait_type": "Type",
                        "value": data.type
                    } : "",
                data.material != "" ?
                    {
                        "trait_type": "Material",
                        "value": data.material
                    } : "",
                {
                    "trait_type": "Key Color",
                    "value": data.key_color
                },
                {
                    "trait_type": "Bow",
                    "value": data.bow
                },
                {
                    "trait_type": "Chain",
                    "value": data.chain
                },
                {
                    "trait_type": "Closed Ward Shapes",
                    "value": data.closed_ward_shapes
                },
                {
                    "trait_type": "Collor Type",
                    "value": data.collar_type
                },
                {
                    "trait_type": "Extra",
                    "value": data.extra
                },
                {
                    "trait_type": "Eye Color",
                    "value": data.eye_color
                },
                {
                    "trait_type": "Key Bit Type",
                    "value": data.key_bit_type
                },
                {
                    "trait_type": "Key Color",
                    "value": data.key_color
                },
                {
                    "trait_type": "KeyFob",
                    "value": data.key_fob
                },
                {
                    "trait_type": "RN Bit Pattern",
                    "value": data.rn_bit_pattern
                },
                {
                    "trait_type": "Side Cut Wards",
                    "value": data.side_cut_wards
                },
                {
                    "trait_type": "Stem",
                    "value": data.stem
                },
                {
                    "trait_type": "Tip",
                    "value": data.tip
                },
                {
                    "trait_type": "Background",
                    "value": data.background
                },
            ]
        })
        console.log("Json", metadata);
        return metadata;
    }

    const mintYourNFT = async (event) => {
        event.preventDefault();
        setMintButtonContent("Initiated...");
        if (nftLoot != null) {
            // fetch data from api. 
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                //   body: JSON.stringify({ color: colorName, mouth: mouthName, eye: eyeName, picture: image })
            };
            try {

                await fetch(`${apiConstants.base_api_url}/nft_images_mint`, requestOptions)
                    .then(response => response.json())
                    .then(async (data) => {
                        console.log("data", data);
                        setResponseData(data);
                        if (data.success == true) {
                            setMintButtonContent((prevState) => "Connecting to Blockchain");

                            let imageData = await convertDataURIToBinaryFF(
                                "data:image/png;base64," + data.data.picture_base
                            );

                            imageData = Buffer(imageData);

                            let allWords = data.data.properties.type + " " + data.data.properties.material + " " + data.data.properties.key_color + " "
                                + data.data.properties.bow + " " + data.data.properties.chain + " " + data.data.properties.closed_ward_shapes + " "
                                + data.data.properties.collar_type + " " + data.data.properties.extra + " " + data.data.properties.eye_color + " "
                                + data.data.properties.key_bit_type + " " + data.data.properties.key_fob + " " + data.data.properties.rn_bit_pattern + " "
                                + data.data.properties.side_cut_wards + " " + data.data.properties.stem + " " + data.data.properties.tip + " " + data.data.properties.background;

                            //adding file to the IPFS

                            ipfs.add(imageData, async (error, result) => {
                                console.log("Ipfs result", result);
                                if (error) {
                                    console.error(error);
                                    return;
                                }
                                setImg(result[0].hash);

                                const json = generateJson({
                                    name: data.data.name, description: data.data.description, imageHash: result[0].hash,
                                    type: data.data.properties.type, material: data.data.properties.material, key_color: data.data.properties.key_color,
                                    bow: data.data.properties.bow, chain: data.data.properties.chain, closed_ward_shapes: data.data.properties.closed_ward_shapes,
                                    collar_type: data.data.properties.collar_type, extra: data.data.properties.extra, eye_color: data.data.properties.eye_color,
                                    key_bit_type: data.data.properties.key_bit_type, key_fob: data.data.properties.key_fob, rn_bit_pattern: data.data.properties.rn_bit_pattern,
                                    side_cut_wards: data.data.properties.side_cut_wards, stem: data.data.properties.stem, tip: data.data.properties.tip, background: data.data.properties.background,
                                    allWords: allWords
                                }).then(async (val) => {
                                    try {
                                        const cid = await ipfsJson.add(val);
                                        const tokenURIHash = await ipfsJson.cat(cid);
                                        console.log("cid", cid);
                                        console.log("minter address", minterWalletAddress);
                                        console.log("all words", allWords);
                                        nftLoot.methods
                                            .safeMint(minterWalletAddress, "https://ipfs.infura.io/ipfs/" + cid, allWords)
                                            .send({ from: account })
                                            .on("error", (error) => {
                                                let notificationMessage;
                                                if (error.message == undefined) {
                                                    notificationMessage = getErrorNotificationMessage(
                                                        "Same Wallet can't have more than 2 NFT! Use different wallet address"
                                                    );
                                                } else {
                                                    notificationMessage = getErrorNotificationMessage(
                                                        error.message
                                                    );
                                                }
                                                props.dispatch(createNotification(notificationMessage));
                                                setMintButtonContent("");
                                            })
                                            .once("receipt", (receipt) => {
                                                console.log("rece", receipt);
                                                setLoading(false)
                                                setMintButtonContent("");
                                                setImages((prevState) => ([...prevState, result[0].hash]))
                                                setNftWords((prevState) => ([...prevState, allWords]));
                                                sendTransactionApi(receipt.transactionHash, data.data.nft_image_id)
                                            });
                                    } catch (error) {
                                        console.log("Error", error);
                                        const notificationMessage = getErrorNotificationMessage(
                                            "Invalid wallet address"
                                        );
                                        props.dispatch(createNotification(notificationMessage));
                                        setMintButtonContent("");
                                    }
                                });
                            });
                        } else {
                            setMintButtonContent("");
                            window.alert(data.error);
                        }

                    });
            } catch (error) {
                setMintButtonContent("");
                const notificationMessage = getErrorNotificationMessage(
                    "Something went wrong. Please refresh the page and try again."
                );
                props.dispatch(createNotification(notificationMessage));
            }
        } else {
            setMintButtonContent("");
            const notificationMessage = getErrorNotificationMessage(
                "Something went wrong. Please refresh the page and try again."
            );
            props.dispatch(createNotification(notificationMessage));
        }
    }

    const sendTransactionApi = (transactionHash, nft_image_id) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ transaction_id: transactionHash, wallet_address: minterWalletAddress, nft_image_id: nft_image_id })
        };
        try {

            fetch(`${apiConstants.base_api_url}/nft_images_complete` , requestOptions)
                .then(response => response.json())
                .then((data) => {
                    console.log("success data", data)
                    if (data.success) {
                        console.log("Success")
                    } else {
                        console.log("error");
                    }
                });
        }
        catch (error) {
            console.log("error", error);
        }
    }



    const loadWeb3 = async () => {
        setStartFetching(true);
        if (window.ethereum) {
            window.web3 = new Web3(configuration.get("configData.infura_key"));
            loadBlockchainData();
            return true;
        } else if (window.web3) {
            window.web3 = new Web3(configuration.get("configData.infura_key"));
            loadBlockchainData();
            return true;
        } else {
            window.web3 = new Web3(configuration.get("configData.infura_key"));
            loadBlockchainData();
            return true;
        }
    }

    const loadBlockchainData = async () => {
        const web3 = window.web3;
        // Network ID
        const networkId = await web3.eth.net.getId();
        const networkData = NftLoot.networks[networkId];
        if (networkData) {
            const nftLoot = new web3.eth.Contract(NftLoot.abi, configuration.get("configData.contract_address"));
            setNftLoot(nftLoot);
            console.log("NFTloot", nftLoot);
            const totalSupply = await nftLoot.methods.MAX_SUPPLY().call();
            setTotalSupply(totalSupply);
            // fetch tokenURI.
            fetchDatafromBlockchain(nftLoot, totalSupply);
            setStartFetching(false);
        } else {
            window.alert("Contract not deployed to detected network.");
        }
    }

    const loadMore = event => {
        event.preventDefault();
        setLoadMoreContent("Loading....");
        fetchDatafromBlockchain(nftLoot, totalSupply);
        setLoadMoreLoading(true);
    }

    const fetchDatafromBlockchain = async (nftLoot, totalSupply) => {

        console.log("initial count", initCount);
        console.log("fetch count", fetchCount);
        const tempFetchCount = fetchCount;
        const tempInitCount = initCount + 1;
        // Load images
        let loopCount = 0;
        if (initCount <= totalSupply) {
            for (var i = tempInitCount; i <= tempFetchCount; i++) {
                if (i <= totalSupply) {
                    // get the token ID. 
                    const tokenID = await nftLoot.methods.tokenByIndex(i - 1).call();
                    if (tokenID) {
                        // get the token URL 
                        const tokenURI = await nftLoot.methods.tokenURI(tokenID).call();
                        const response = await fetch(tokenURI);
                        const jsonData = await response.json();
                        setData((prevState) => ([...prevState, jsonData]));
                        setTokenURLs((prevState) => ([...prevState, tokenURI]));
                    }
                    loopCount++;
                } else {
                    console.log("error mssg");
                    setNoMoreData(true);
                }
            }

            console.log("asdfasdf", loopCount);
            setInitCount((prevState) => prevState + loopCount);
            setFetchCount((prevState) => prevState + 6);

        } else {
            // return true;
            // Errror msg
            console.log("eeror mssg");
        }
        setLoading(false);
        setLoadMoreLoading(false);
        setLoadMoreContent("");
    }

    useEffect(() => {

    }, [])


    return (
        <>

            <section className="nft-keys-sec">
                <Container>
                    <Row>
                        <div className="row margin-top-lg align-items-center">
                            <div className="col-md-6">
                                <div className="nft-brand-info">
                                    <h2>{InputData.banner_title}</h2>
                                    <h4>3,350/3,350 minted</h4>
                                    <p>{InputData.banner_description}</p>
                                    <h3>SOLD OUT</h3>
                                </div>
                                <div className="nft-social-share-sec">
                                    <ul className="nft-social-share-list list-unstyled">
                                        <li>
                                            <a href={InputData.opensea_link}>OpenSea</a>
                                        </li>
                                        <li>
                                            <a href={InputData.contract_address}>Contract</a>
                                        </li>
                                    </ul>
                                    <a href="#" className="follow-us-btn">
                                        Follow Us On
                                    </a>
                                    <ul className="nft-social-share-link list-unstyled">
                                        {InputData.follow_icons.length > 0
                                            ? InputData.follow_icons.map((social) => {
                                                return (
                                                    <li>
                                                        <a href={social.link}>
                                                            <img
                                                                className="social-icons"
                                                                src={social.icon}
                                                                alt=""
                                                            />
                                                        </a>
                                                    </li>
                                                );
                                            })
                                            : ""}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="nft-loot-banner-img-sec">
                                    <img src={InputData.img_cartoon} />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
            <Container>
                <Row className="home-header">
                    <div className="nft-search-wallet-address-sec center">
                        {connectMetaMask == false ? "" :
                            <>
                                <p>Wallet Address: {account}</p>
                                <p>Balance: {window.web3.utils.fromWei(ethBalance, "Ether")}</p>
                            </>
                        }
                        <Form className="nft-search-wallet-address-form" onSubmit={mintYourNFT}>
                            <Form.Group>
                                <Form.Control type="text" placeholder="Enter Your Wallet Address" disabled={connectMetaMask ? false : true}
                                    value={minterWalletAddress} onChange={(event) => setMinterWalletAdddress(event.target.value)} />
                            </Form.Group>
                            <div className="min-nft-btn-sec">
                                {connectMetaMask == true ?
                                    <Button className="mint-nft-btn" onClick={mintYourNFT} type="submit" disabled={mintButtonContent != "" ? true : false}>
                                        {mintButtonContent != "" ? mintButtonContent : "Mint NFT"}
                                    </Button>
                                    : <Button className="mint-nft-btn" onClick={connectingMetaMask}>
                                        Connect Wallet
                                    </Button>}
                            </div>
                        </Form>
                    </div>
                </Row>
            </Container>
            <section className="sm-padding nft-key-img-sec">
                <Container>
                    <h2 className="image-title">Example NFT’s</h2>
                    <Row>

                        {/* <div className="nft-loot-box"> */}
                        {loading ? "Loading..." : data.length > 0 ? data.map((value, index) => {
                            return (
                                <Col md={4}>
                                    <Link to={`/assets/${InputData.contract_address}/${index + 1}`}>
                                        <div className="nft-loot-card">
                                            <div className="nft-loot-img-sec">
                                                <Image
                                                    className="nft-loot-img"
                                                    src={value.image}
                                                />
                                            </div>
                                            <p>{value.name}</p>
                                        </div>
                                    </Link>
                                </Col>
                            )
                        }) : ""}
                        {/* </div> */}
                    </Row>

                    {loadMoreLoading ? "Loading..." : ""}
                    <div>
                        <div className="col-md-12">
                            <div className="nft-loot-load-more-btn-sec">
                                {noMoreData ? (
                                    "No More Data"
                                ) : (
                                    <a href="#" className="load-more-btn" onClick={loadMore}>
                                        Load More
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};


function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(null, mapDispatchToProps)(HomePageIndex);
